import React from "react";
import styled from "styled-components";

import Error from "../components/icons/Error";
import FlexRow from "./layout/FlexRow";

const DropDownContainer = styled.div`
  margin-top: 40px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1080px) {
    margin-top: 0px;
  }
`

const StyledDropDown = styled.select`
  max-width: 320px;
  height: 42px;
  background-color: #FFFFFF;
  margin: 10px 10px 6px 0px;
  padding: 0px 10px;
  border: ${(props) => props.error ? '1px solid #C91432' : '1px solid #E2E2E2'};
  font-family: Helvetica Neue;
  font-size: 14pt;
  font-family: 'Source Sans Pro';
  :focus-visible {
    border: 1px solid gray;
  }
  :disabled {
    border: 1px solid #CCCCCC;
    background-color: #F0F0F0;
  }

  @media screen and (max-width: 800px) {
    min-width: 100%;
    margin-top: 8px;
    margin-right: 0px;
    font-size: 14px;
  }

  @media screen and (min-width: 801px) and (max-width: 1080px) {
    width: 328px;
    margin-bottom: 16px;
  }
`

const ErrorMessageContainer = styled.div`
  height: 30px;
`

const ErrorMessageText = styled.p`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #C91432;
  margin-top: 0px;
`

const ErrorIconContainer = styled.div`
  position: absolute;
  margin-left: 290px;
  margin-top: 12px;
  @media screen and (max-width: 800px) {
    height: 40px;
    margin-left: 180%;
    margin-top: 20px;
  }
  @media screen and (max-width: 350px) {
    height: 40px;
    width: 100%;
    margin-left: 175%;
    margin-top: 20px;
  }
`

export default function DropDown(props) {
  const { id, name, placeholder, onChange, value, state, error, errorMessageContainer, errorMessage, disabled, width, options } = props;
  return (
    <DropDownContainer>
      <FlexRow>
        <StyledDropDown
          id={id}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          state={state}
          error={error}
          disabled={disabled}
          width={width}
        >
          {options.map((value, index) => <option key={`key-${index+1}`} value={value}>{value}</option>)}
        </StyledDropDown>
        {error ? (
          <ErrorIconContainer>
            <Error />
          </ErrorIconContainer>
        ) : ''}
      </FlexRow>
      {errorMessageContainer ? (
        <ErrorMessageContainer>
          {errorMessage ? <ErrorMessageText>{errorMessage}</ErrorMessageText> : ''}
        </ErrorMessageContainer>
      ) : ''}
    </DropDownContainer>
  );
}