import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

import { context } from '../state/context';
import actions from '../state/actions';

import useCreateEnrollmentTask from '../hooks/useCreateEnrollmentTask';

import Header from "../components/Header";
import AddressTable from "../components/AddressTable";
import QuoteDetails from "../components/QuoteDetails";
import FlexColumn from "../styled-components/layout/FlexColumn";
import FlexRow from "../styled-components/layout/FlexRow";
import PageContainer from "../styled-components/layout/PageContainer";
import BackLink from "../styled-components/BackLink";
import DropDown from "../styled-components/DropDown";
import Text from "../styled-components/Text";
import TextInput from "../styled-components/TextInput";
import Button from "../styled-components/Button";
import Footer from '../components/Footer';
import Title from "../styled-components/Title";
import SubTitle from "../styled-components/SubTitle";
import CheckBox from "../styled-components/CheckBox";


const layoutSpacingBase = '93px';

const BackLinkContainer = styled.div`
  margin: 20px ${layoutSpacingBase} 12px;
  display: ${(props) => props.display};
  @media screen and (max-width: 800px) {
    margin: 20px 25px 12px;
  }
`

const TitleContainer = styled.div`
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  @media screen and (max-width: 800px) {
    margin-bottom: 10px;
  }
`

const StyledDiv = styled.div`
  margin-bottom: 24px;
  margin-top: 40px;
  @media screen and (max-width: 800px) {
    margin-top: 0px;
    margin-bottom: 10px;
  }
`

const TextContainer = styled.div`
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  width: ${(props) => props.width};
  @media screen and (max-width: 800px) {
    padding-right: 24px;
  }
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  padding-left: ${layoutSpacingBase};
  padding-right: ${layoutSpacingBase};
  margin-bottom: 24px;
  @media screen and (max-width: 800px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`

const InvalidInfoContainer = styled.div`
  height: 48px;
  background-color: #C91432;
  color: white;
  display: flex;
  align-items: center;
  padding-left: 10px;
  line-height: 28px;
  margin-top: 16px;
`
const WhiteText = styled.p`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  @media screen and (max-width: 800px) {
    font-size: 12px;
    line-height: 12px;
  }
`

const Divider = styled.div`
  height: 24px;
  border-bottom: 1px solid #CCCCCC;
  margin-bottom: 24px;
`
const DividerNoHeight = styled(Divider)`
  height: 0px;
`

const CardTitle = styled.h2`
  font-family: 'Publico Headline';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #343C3D;
  margin: 0px;
  @media screen and (max-width: 800px) {
    font-size: 24px;
    line-height: 150%;
  }
`

const CheckBoxContainer = styled.div`
  margin-bottom: 32px;
  width: ${(props) => props.width}
`

const PriceTitle = styled.h2`
  font-family: 'Publico Headline';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #343C3D;
  margin: 0px;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    line-height: 24px;
  }
`

const SmallText = styled.p`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #343C3D;
  text-align: left;
  margin: 0;
  margin-top: 10px;
  @media screen and (max-width: 800px) {
    font-size: 11px;
    line-height: 16px;
  }
`

const ErrorMessageText = styled.p`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #C91432;
  margin-top: 0px;
`


export default function Enrollment() {
  const { state, dispatch } = useContext(context);
  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [errorMessage, setErrorMessage] = useState('');
  const [taskPayload, setTaskPayload] = useState(null);
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [checkbox4, setCheckbox4] = useState(false);
  
  const {
    result: enrollmentTaskResult,
    error: enrollmentTaskError
  } = useCreateEnrollmentTask(taskPayload);

  const dropDownValues = [
    'Tipo de identificación',
    '1-Credencial para votar',
    '2-Pasaporte',
    '3-Cédula profesional',
    '4-Cartilla del Servicio Militar Nacional',
    '5-Certificado de Matricula Consular',
    '6-Tarjeta Unica de Identidad Militar',
    '7-Credenciales y/o Carnets de afiliacion al IMSS',
    '8-Credenciales y/o Carnets de afiliación al ISSSTE',
    '9-Tarjeta de afiliación al Instituto Nacional de las Personas Adultas Mayores',
    '10-Licencia para conducir',
    '11-Formato Migratorio residencia temporal',
    '12-Formato Migratorio residencia permanente'
  ]; // First value must be Tipo de identificación
  const issuerIds = {
    'Tipo de identificación': '',
    '1-Credencial para votar': 'Instituto Nacional Electoral',
    '2-Pasaporte': 'Secretaria de Relaciones Exteriores',
    '3-Cédula profesional': 'Secretaria de Educación Pública',
    '4-Cartilla del Servicio Militar Nacional': 'Secretaria de la Defensa Nacional',
    '5-Certificado de Matricula Consular': 'Secretaria de Relaciones Exteriores',
    '6-Tarjeta Unica de Identidad Militar': 'Secretaria de la Defensa Nacional',
    '7-Credenciales y/o Carnets de afiliacion al IMSS': 'Instituto Mexicano del Seguro Social',
    '8-Credenciales y/o Carnets de afiliación al ISSSTE': 'Instituto de Seguridad y Servicios Sociales de los Trabajadores del Estado',
    '9-Tarjeta de afiliación al Instituto Nacional de las Personas Adultas Mayores': 'Instituto Nacional de las Personas Adultas Mayores',
    '10-Licencia para conducir': 'Secretarias de Movilidad y Transporte de los Estados',
    '11-Formato Migratorio residencia temporal': 'Instituto Nacional de Migración',
    '12-Formato Migratorio residencia permanente': 'Instituto Nacional de Migración'
  };

  const handleValidation = async (e) => {
    e.preventDefault();
    const payload = {};

    setErrorMessage('');

    // Validations
    if (!state.beneficiary.email || state.beneficiary.email === '') {
      setErrorMessage('El correo electrónico es requerido.');
      return;
    }
    if (!state.beneficiary.phone || state.beneficiary.phone === '') {
      setErrorMessage('El número telefónico es requerido.');
      return;
    }
    if (!state.beneficiary.birthdate || state.beneficiary.birthdate === '') {
      setErrorMessage('La fecha de nacimiento es requerida.');
      return;
    }
    if (!state.beneficiary.bank_account_number || state.beneficiary.bank_account_number === '') {
      setErrorMessage('La cuenta CLABE es requerida.');
      return;
    }
    if (
      !state.beneficiary.type_id || state.beneficiary.type_id === '' ||
      !state.beneficiary.issuer_id || state.beneficiary.issuer_id === '' ||
      !state.beneficiary.id_number || state.beneficiary.id_number === ''
    ) {
      setErrorMessage('Debe entrar todos los detalles de identificación.');
      return;
    }
    if (state.beneficiary.id_number.length < 5) {
      setErrorMessage('Debe entrar un número de identificación válido.');
      return;
    }
    if (!checkbox1 || !checkbox2 || !checkbox3 || !checkbox4) {
      setErrorMessage('Debe aceptar todas las opciones en leyenda.');
      return;
    }

    dispatch({ type: actions.SetIsProcessing, payload: true });

    payload.policy = { ...state.beneficiary };
    payload.locations = state.enrollments.map(enrollment => {
      const _enrollment = {
        location_id: enrollment.location_id,
        enrollment_id: enrollment._id.toString()
      };

      const converages = ['coverage_amount_0', 'coverage_amount_1', 'coverage_amount_2', 'coverage_amount_3']
      const premiums = ['total_prime_0', 'total_prime_1', 'total_prime_2', 'total_prime_3']

      converages.forEach((cover, i) => {
        if (
          enrollment.selectedCoverage &&
          enrollment.selectedCoverage.selected &&
          cover === enrollment.selectedCoverage.cover
        ) {
          _enrollment[cover] = enrollment.selectedCoverage.cantidad.toString();
          _enrollment[premiums[i]] = enrollment.selectedCoverage.prima.toString();
        } else {
          _enrollment[cover] = '';
          _enrollment[premiums[i]] = '';
        }
        if (cover === 'coverage_amount_0') {
          _enrollment[cover] = '10000';
          _enrollment[premiums[i]] = '';
        }
      });

      return _enrollment;
    });

    // Submitting form
    setTaskPayload(payload);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let action;
    switch (name) {
      case 'email':
        action = actions.SetBeneficiaryEmail;
        break;
      case 'phone':
        action = actions.SetBeneficiaryPhone;
        break;
      case 'birthdate':
        action = actions.SetBeneficiaryBirthdate;
        break;
      case 'bank_account_number':
        action = actions.SetBeneficiaryAccountNumber;
        break;
      case 'id_number':
        action = actions.SetBeneficiaryIdNumber;
        break;
      case 'type_id':
        action = actions.SetBeneficiaryTypeId;
        break;
      default:
        action = '';
    }
    dispatch({ type: action, payload: value });
    if (name === 'type_id') {
      const issuerIdValue = issuerIds[value];
      dispatch({ type: actions.SetBeneficiaryIssuerId, payload: issuerIdValue });
    }
  };

  useEffect(() => {
    if (state.enrollments.length === 0) {
      navigate('/axa');
    }
  }, [state.enrollments, navigate]);

  useEffect(() => {
    if (enrollmentTaskResult) {
      navigate('/axa/gracias');
    }
    if (enrollmentTaskError) {
      setErrorMessage(enrollmentTaskError.message);
    }
    dispatch({ type: actions.SetIsProcessing, payload: false });
  }, [dispatch, navigate, enrollmentTaskResult, enrollmentTaskError]);

  // Checks window width so we can set error message position
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    // Set checkbox values into state
    dispatch({ type: actions.SetIsIdentityDataCorrect, payload: checkbox1 });
  }, [dispatch, checkbox1]);
  useEffect(() => {
    // Set checkbox values into state
    dispatch({ type: actions.SetIsBankAccountCorrect, payload: checkbox2 });
  }, [dispatch, checkbox2]);
  useEffect(() => {
    // Set checkbox values into state
    dispatch({ type: actions.SetPrivacyValidation1, payload: checkbox3 });
  }, [dispatch, checkbox3]);
  useEffect(() => {
    // Set checkbox values into state
    dispatch({ type: actions.SetPrivacyValidation2, payload: checkbox4 });
  }, [dispatch, checkbox4]);

  return (
    <PageContainer>
      <Header />
      <BackLinkContainer display="block">
        <BackLink to="/axa/como-funciona">Atrás</BackLink>
      </BackLinkContainer>
      <StyledDiv>
        <SubTitle>AXA México</SubTitle>
        <Title>Hola, {state.enrollments.length > 0 ? state.enrollments[0].holder_first_name : 'Cliente'}</Title>
      </StyledDiv>
      <TextContainer marginTop="0px" marginBottom="24px" marginLeft={windowWidth > 800 ? layoutSpacingBase : '25px'} marginRight={windowWidth > 800 ? '0px' : '50px'}>
        <Text>Gracias por confiar en nosotros, por favor captura los datos faltantes.</Text>
      </TextContainer>
      <StyledForm onSubmit={handleValidation}>
        <FlexRow justifyContent="start" width="100%" responsiveWidth="1080px" margin="0px 0px 15px">
          <TextInput label="Póliza" placeholder="YHM125489635" disabled={true} value={state.external_policy_id} />
          <TextInput label="RFC del contratante" placeholder="ABCD123456-XXX" disabled={true} value={state.incumbent_rfc} />
        </FlexRow>
        <FlexRow justifyContent="start" width="100%" responsiveWidth="1080px" margin="0px 0px 15px">
          <TextInput label="Nombre / Razón social" value={state.enrollments.length > 0 ? state.enrollments[0].incumbent_first_name : ''} disabled={true} />
          <TextInput label="Primer apellido" value={state.enrollments.length > 0 ? state.enrollments[0].incumbent_last_name_1 : ''} disabled={true} />
          <TextInput label="Segundo apellido" value={state.enrollments.length > 0 ? state.enrollments[0].incumbent_last_name_2 : ''} disabled={true} />
        </FlexRow>
        <FlexRow justifyContent="start" width="100%" responsiveWidth="1080px" margin="0px 0px 15px">
          <TextInput name="email" label="Correo electónico" value={state.beneficiary.email} placeholder="nombre@correo.com" onChange={handleChange} />
          <TextInput name="phone" label="Teléfono celular" value={state.beneficiary.phone} placeholder="00 0000 0000" mask={[/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]} onChange={handleChange} />
          <TextInput label="Moneda de la póliza" placeholder="MXN" disabled={true} />
        </FlexRow>
        <InvalidInfoContainer>
          <WhiteText>Si algún dato de la información mostrada es incorrecto, por favor contacta a tu agente para que gestione la corrección en tu póliza Planprotege Daños.</WhiteText>
        </InvalidInfoContainer>
        <Divider />

        <TitleContainer marginTop="0px" marginBottom="25px" marginLeft={windowWidth > 800 ? `-${layoutSpacingBase}` : '-25px'} marginRight="16px">
          <Title>Ubicaciones Aseguradas</Title>
        </TitleContainer>
        <Text>A continuación, se muestran las ubicaciones vigentes de tu póliza Planprotege Daños.</Text>
        <br />
        <Text>En nombre de tu agente de seguros, te presentamos las opciones de aseguramiento adicional al obsequio para nuestra cobertura de Ayuda Express Huracán, disponibles para las ubicaciones elegibles.</Text>

        {state.enrollments.map(enrollment => (
          <AddressTable
            {...enrollment}
            key={`${enrollment._id}`}
            isAddressCovered={enrollment.within_shape}
          />
        ))}

        <Divider />
        <FlexRow responsiveWidth="1080px">
          <FlexColumn>
            <TitleContainer marginTop="24px" marginBottom="16px" marginLeft="0px" marginRight={windowWidth > 800 ? '16px' : '35spx'}>
              <CardTitle>Compártenos tu información adicional (asegurado)</CardTitle>
            </TitleContainer>
            <TextContainer width={windowWidth > 800 ? '642px' : '100%'} marginTop="0px" marginBottom="16px" marginLeft="0px" marginRight="0px">
              <Text>Por favor proporciónanos la siguiente información que nos ayudará a realizar el pago ágilmente en caso de que se cumplan los parámetros establecidos. Para asegurados personas morales, deben ser los datos del representante legal.</Text>
            </TextContainer>
            <FlexRow justifyContent="start" width={windowWidth > 800 ? '55%' : '100%'} responsiveWidth="1080px" disabled={false} margin="0px 0px 15px" >
              <TextInput name="birthdate" value={state.beneficiary.birthdate} label="Fecha de nacimiento / constitución" placeholder="00/00/0000"  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} onChange={handleChange} disabled={false} />
              <TextInput name="bank_account_number" value={state.beneficiary.bank_account_number} label="Cuenta CLABE" placeholder="000000000000000000" mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} onChange={handleChange} maxLength={18} disabled={false} />
            </FlexRow>
            <FlexRow justifyContent="start" width="100%" responsiveWidth="1080px" margin="0px 0px 15px">
              <DropDown name="type_id" options={dropDownValues} errorMessageContainer={false} onChange={handleChange} errorMessage="Error" error={false} />
              <TextInput name="issuer_id" value={state.beneficiary.issuer_id} label="Emisor de identificación" placeholder="Emisor" disabled={true} />
              <TextInput name="id_number" value={state.beneficiary.id_number} label="Número de identificación" placeholder="Número de ID" onChange={handleChange} disabled={false} maxLength={32} />
            </FlexRow>
          </FlexColumn>
        </FlexRow>

        <Divider />
        <FlexColumn width={windowWidth > 1080 ? '63.8%' : '100%'}>
          <TitleContainer marginTop="12px" marginBottom="32px">
            <CardTitle>Resumen opciones de aseguramiento</CardTitle>
          </TitleContainer>
          {state.enrollments.map(enrollment => (
            <QuoteDetails key={`${enrollment._id}`} {...enrollment} />
          ))}
          <TitleContainer marginTop={windowWidth > 800 ? '12px' : '-8px'} marginBottom="32px">
            <PriceTitle>
              Prima total a pagar (MXN)*: ${(state.enrollments.reduce((acc, enrollment) => acc + (enrollment.selectedCoverage ? enrollment.selectedCoverage.prima : 0), 0)).toLocaleString()}
            </PriceTitle>
            <SmallText>*Corresponde a la prima total por la cobertura Ayuda Express Huracán, por un periodo cubierto de más de 5 meses. Si el periodo cubierto es menor, la prima total disminuye de acuerdo con lo establecido en las condiciones generales de tu póliza Planprotege Daños. Debe ser pagada en una sola exhibición.</SmallText>
          </TitleContainer>
          <DividerNoHeight />
          <CheckBoxContainer width="100%">
            <CheckBox onChange={() => setCheckbox1(!checkbox1)} checked={checkbox1}>Hago constar que la información contenida en la presente alta de cobertura corresponde a la que se indica en mi identificación oficial vigente.</CheckBox>
          </CheckBoxContainer>
          <CheckBoxContainer width="100%">
            <CheckBox onChange={() => setCheckbox2(!checkbox2)} checked={checkbox2}>Manifiesto bajo protesta de decir verdad, que soy titular de la cuenta CLABE indicada y que la misma se encuentra activa, por lo que autorizo a AXA Seguros SA de CV para que efectúe el depósito de mi indemnización en dicha cuenta en caso de siniestro procedente.</CheckBox>
          </CheckBoxContainer>
          <CheckBoxContainer width="100%">
            <CheckBox onChange={() => setCheckbox3(!checkbox3)} checked={checkbox3}>Conozco el alcance de la cobertura, así como que para la emisión del endoso utilizarán los datos de mi póliza Planprotege Daños.  Confirmo la oferta de contratación con las sumas aseguradas y prima.</CheckBox>
          </CheckBoxContainer>
          <DividerNoHeight />
          <TextContainer marginBottom="32px">
            <Text>AXA Seguros, S.A. de C.V., con domicilio en Avenida Félix Cuevas número 366, piso 3, Colonia Tlacoquemécatl, Alcaldía Benito Juárez, C.P. 03200, Ciudad de México,  llevará a cabo el tratamiento de sus datos personales para todos los fines vinculados con la relación jurídica/ contractual que tengamos celebrado o en su caso se vaya a celebrar con usted, para darlo de alta en la cobertura y  finalidades previstas en el aviso de privacidad integral, que puede consultar en axa.mx en la sección Aviso de Privacidad.</Text>
          </TextContainer>
          <CheckBoxContainer width="100%">
            <CheckBox onChange={() => setCheckbox4(!checkbox4)} checked={checkbox4}>Autorizo el tratamiento de mis datos personales financieros y patrimoniales para efectos de lo antes previsto, así como la transferencia de los datos personales aquí descritos para las finalidades mencionadas.</CheckBox>
          </CheckBoxContainer>
        </FlexColumn>
        {errorMessage !== '' ? (<ErrorMessageText>{errorMessage}</ErrorMessageText>) : null}
        <Button
          text="Completar"
          defaultValue="submit"
          disabled={state.is_processing}
          isProcessing={state.is_processing}
        />
        <br />
      </StyledForm>
      <Footer position="relative" />
    </PageContainer>
  );
}