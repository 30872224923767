import React from "react";
import styled from "styled-components";

// todo: wuitar margin right para añadirlo a los text containers de cada página
const StyledText = styled.p`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #343C3D;
  text-align: left;
  margin: 0;
  @media screen and (max-width: 800px) {
    font-size: 14px;
    line-height: 150%;
  }
`

export default function Text(props) {
  return (
    <StyledText>
      {props.children}
    </StyledText>
  );
}