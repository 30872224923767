import React from "react";
import styled from "styled-components";
// import InputMask from 'react-input-mask';
import MaskedInput from 'react-text-mask'

import Error from "../components/icons/Error";
import FlexRow from "./layout/FlexRow";

const StyledLabel = styled.label`
  color: #343C3D;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-top: 10px;
  font-family: 'Source Sans Pro';
  @media screen and (max-width: 800px) {
    font-size: 12px;
    margin-top: 0px;
  }
  @media screen and (min-width: 801px) {
    max-width: 340px;
  }
`

const StyledMaskedInput = styled(MaskedInput)`
  min-width: 320px;
  height: 40px;
  background: #FFFFFF;
  margin-top: 16px;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 0px 0px 0px 5px;
  border: ${(props) => props.error === 'true' ? '1px solid #C91432' : '1px solid #E2E2E2'};
  font-family: Helvetica Neue;
  font-size: 14pt;
  font-family: 'Source Sans Pro';
  ::placeholder {
    color: #757575;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-family: 'Source Sans Pro';
  }
  :focus-visible {
    border: 1px solid gray;
  }
  :disabled {
    border: 1px solid #CCCCCC;
    background-color: #F0F0F0;
  }

  @media screen and (max-width: 800px) {
    min-width: 0px;
    width: 100%;
    margin-top: 8px;
    margin-right: 0px;
  }
`

const StyledInput = styled.input`
  min-width: 320px;
  height: 40px;
  background: #FFFFFF;
  margin-top: 16px;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 0px 0px 0px 5px;
  border: ${(props) => props.error ? '1px solid #C91432' : '1px solid #E2E2E2'};
  font-family: Helvetica Neue;
  font-size: 14pt;
  font-family: 'Source Sans Pro';
  ::placeholder {
    color: #757575;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-family: 'Source Sans Pro';
  }
  :focus-visible {
    border: 1px solid gray;
  }
  :disabled {
    border: 1px solid #CCCCCC;
    background-color: #F0F0F0;
  }

  @media screen and (max-width: 800px) {
    min-width: 0px;
    width: 100%;
    margin-top: 8px;
    margin-right: 0px;
  }
`

const ErrorMessageContainer = styled.div`
  min-height: 30px;
`

const ErrorMessageText = styled.p`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #C91432;
  margin-top: 0px;
`

const ErrorIconContainer = styled.div`
  position: absolute;
  margin-left: 300px;
  margin-top: 12px;
  @media screen and (max-width: 800px) {
    height: 40px;
    margin-top: 20px;
    width: auto;
    right: 40px;
    margin-left: 0;
  }
  @media screen and (max-width: 350px) {
    height: 40px;
    width: 100%;
    margin-left: 175%;
    margin-top: 20px;
  }
`

export default function TextInput(props) {
  const { id, name, placeholder, onChange, value, state, error, errorMessageContainer, errorMessage, readOnly, disabled, width, mask, maxLength } = props;
  return (
    <StyledLabel htmlFor={id}>
      {props.label}
      <FlexRow alignItems="center" justifyContent="start">
        {mask ? (
          <StyledMaskedInput
            id={id}
            name={name}
            type="text"
            placeholder={placeholder}
            onChange={onChange}
            value={value || ''}
            state={state}
            error={error ? error.toString() : 'false'}
            readOnly={readOnly || false}
            disabled={disabled}
            width={width}
            mask={mask || false}
          />
        ) : (
          <StyledInput
            id={id}
            name={name}
            type="text"
            placeholder={placeholder}
            onChange={onChange}
            value={value || ''}
            state={state}
            error={error}
            readOnly={readOnly || false}
            disabled={disabled}
            width={width}
            maxLength={maxLength || 64}
          />
        )}
        {error ? (
          <ErrorIconContainer>
            <Error />
          </ErrorIconContainer>
        ) : ''}
      </FlexRow>
      {errorMessageContainer ? (
        <ErrorMessageContainer>
          {errorMessage ? <ErrorMessageText>{errorMessage}</ErrorMessageText> : ''}
        </ErrorMessageContainer>
      ) : ''}
    </StyledLabel>
  );
}