// The global state for the react application
// Write JS docs for the Enrollment class

export class Enrollment {
  constructor() {
    this.external_policy_id = '';
    this.location_id = '';
    this.currency = '';
    this.holder_first_name = '';
    this.holder_last_name_1 = '';
    this.holder_last_name_2 = '';
    this.agent_id = '';
    this.coverage_amount_0 = '';
    this.coverage_amount_1 = '';
    this.coverage_amount_2 = '';
    this.coverage_amount_3 = '';
    this.total_prime_0 = '';
    this.total_prime_1 = '';
    this.total_prime_2 = '';
    this.total_prime_3 = '';
    this.parametric_end_date = '';
    this.parametric_start_date = '';
    this.active = '';
    this.incumbent_rfc = '';
    this.incumbent_first_name = '';
    this.incumbent_last_name_1 = '';
    this.incumbent_last_name_2 = '';
    this.holder_rfc = '';
    this.street = '';
    this.exterior_number = '';
    this.interior_number = '';
    this.suburb = '';
    this.city = '';
    this.state = '';
    this.town = '';
    this.zip_code = '';
    this.validity_end_date = '';
    this.person_type = '';
    this.already_endorsed = '';
    this.previous_external_policy_id = '';
    this.location_status = '';
    this.free_parametric_coverage = '';
    this.peril_id = '';
    this.email = '';
    this.phone = '';
    this.bank_account_number = '';
    this.birthdate = '';
    this.type_id = '';
    this.issuer_id = '';
    this.id_number = '';
    this.is_identity_data_correct = '';
    this.is_bank_account_number_correct = '';
    this.privacy_validation_1 = '';
    this.privacy_validation_2 = '';
    this.created_at = '';
    this.updated_at = '';
  }
}

export const appState = {
  enrollments: [],
  external_policy_id: '',
  incumbent_rfc: '',
  beneficiary: {
    email: '',
    phone: '',
    bank_account_number: '',
    birthdate: '',
    type_id: '',
    issuer_id: '',
    id_number: '',
    is_identity_data_correct: false,
    is_bank_account_number_correct: false,
    privacy_validation_1: false,
    privacy_validation_2: false
  },
  is_processing: false
};

export default appState;
