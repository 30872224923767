import React from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';

import LeftArrow from "../components/icons/LeftArrow";

const StyledLink = styled(Link)`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  color: #00008F;
  width: 70px;
  display: flex;
  justify-content: space-between;
  :hover {
    cursor: pointer;
  }
  align-items: center;
`

export default function BackLink(props) {
  const { children, to } = props;
  return (
    <StyledLink to={to}>
      <LeftArrow /> {children}
    </StyledLink>
  );
}