import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

import Header from "../components/Header";
import PageContainer from "../styled-components/layout/PageContainer";
import FlexRow from "../styled-components/layout/FlexRow";
import FlexColumn from "../styled-components/layout/FlexColumn";
import Text from "../styled-components/Text";
import BackLink from "../styled-components/BackLink";
import Button from "../styled-components/Button";
import Footer from '../components/Footer';
import Title from "../styled-components/Title";
import SubTitle from "../styled-components/SubTitle";


const BackLinkContainer = styled.div`
  margin: 20px 93px 12px;
  display: ${(props) => props.display};
  @media screen and (max-width: 800px) {
    margin: 20px 25px 12px;
  }
`

const StyledDiv = styled.div`
  margin-bottom: 24px;
  margin-top: 40px;
  @media screen and (max-width: 800px) {
    margin-top: 0px;
    margin-bottom: 10px;
  }
`

const TextContainer = styled.div`
  margin: 0px 65px 24px 93px;
  max-width: 595px;
  @media screen and (max-width: 800px) {
    margin-left: 25px;
    margin-right: 25px;
  }
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 93px;
  @media screen and (min-width: 321px) and (max-width: 800px) {
    margin: 25px;
  }
  @media screen and (max-width: 320px) {
    margin: 0px 25px 25px;
  }
`

const StyledBoldText = styled.p`
  max-width: 672px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #343C3D;
  text-align: left;
  margin: 0;
  @media screen and (max-width: 800px) {
    font-size: 14px;
    line-height: 150%;
  }
`

const StyledImage = styled.img`
  display: flex;
  flex-direction: column;
  background-color: #D9D9D9;
  max-width: 350px;
  height: auto;
  margin-top: -280px;
  @media screen and (max-width: 800px) {
    margin-top: 0;
    margin: 0 25px;
  }
  @media screen and (min-width: 800px) and (max-width: 1100px) {
    margin-left: 93px;
    width: 80%;
  }
`

const Li = styled.li`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #343C3D;
  @media screen and (max-width: 800px) {
    font-size: 14px;
    line-height: 150%;
  }
`


export default function HowItWorks() {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  // Checks window width so we can set image position
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <PageContainer>
      <Header />
      <BackLinkContainer display="block">
        <BackLink to="/axa">Atrás</BackLink>
      </BackLinkContainer>
      <StyledDiv>
        <SubTitle>AXA México</SubTitle>
        <Title>Cobertura paramétrica<br />para huracanes</Title>
      </StyledDiv>
      <FlexRow responsiveWidth="1100px">
        <FlexColumn>
          <TextContainer>
            <Text>Un seguro paramétrico es la modalidad de seguro más simple que existe. Paga una cantidad preestablecida en tu póliza cuando ocurre un evento determinado, dentro de ciertas reglas previamente definidas.</Text>
            <br />
            <br />
            <StyledBoldText>¿Cómo funciona?</StyledBoldText>
            <Text>Si se cumple con los siguientes tres criterios, recibirás tu pago por indemnización de las pérdidas. Sin trámites de ajuste tradicional, simple y rápido.</Text>
            <ol>
              <Li>Tienes vigente tu póliza de Planprotege Daños.</Li>
              <Li>Tu negocio está ubicado en un estado del Pacífico a máximo 100 km de la costa.</Li>
              <Li>Un huracán (categoría 3 o superior) toca la costa del Pacífico en el estado en el que se encuentra ubicado tu negocio.</Li>
            </ol>
            <br />
            <Text>Entre más grande sea la intensidad del huracán, mayor será el monto de tu indemnización.</Text>
          </TextContainer>
        </FlexColumn>
        <FlexColumn width={windowWidth > 1100 ? '50%' : '100%'} alignItems="center" justifyContent="center">
          <StyledImage src="/images/payout-table.jpg" />
        </FlexColumn>
      </FlexRow>
      <StyledButtonContainer>
        <Button
          text="Avanzar"
          onClick={() => {
            window.scrollTo(0, 0);
            navigate('/axa/pre-enrolamiento');
          }}
          isProcessing={false}
        />
        <br />
        <br />
      </StyledButtonContainer>
      <Footer position={windowHeight > 1000 ? 'fixed' : 'relative'} />
    </PageContainer>
  );
}
