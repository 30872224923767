import { Route, Routes, Navigate } from 'react-router-dom';

import Login from './containers/Login';
import HowItWorks from './containers/HowItWorks';
import PreEnrollment from './containers/PreEnrollment';
import Thanks from './containers/Thanks';

import './App.css';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={ <Navigate to="/axa" /> }/>
        <Route exact path="/axa" element={<Login />} />
        <Route path="/axa/como-funciona" element={<HowItWorks />} />
        <Route path="/axa/pre-enrolamiento" element={<PreEnrollment />} />
        <Route path="/axa/gracias" element={<Thanks />} />
      </Routes>
    </div>
  );
}

export default App;
