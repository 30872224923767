import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import * as Realm from 'realm-web';

import { useRealmApp } from '../RealmApp';

import { context } from '../state/context';
import actions from '../state/actions';

import useGetPolicyLocations from '../hooks/useGetPolicyLocations';

import Header from "../components/Header";
import FlexRow from "../styled-components/layout/FlexRow";
import PageContainer from "../styled-components/layout/PageContainer";
import Text from "../styled-components/Text";
import TextInput from "../styled-components/TextInput";
import BackLink from "../styled-components/BackLink";
import Button from "../styled-components/Button";
import Footer from '../components/Footer';
import Title from "../styled-components/Title";
import SubTitle from "../styled-components/SubTitle";


const StyledDiv = styled.div`
  margin-top: 40px;
  @media screen and (max-width: 800px) {
    margin-top: 0px;
    margin-bottom: 10px;
  }
`

const BackLinkContainer = styled.div`
  margin: 20px 93px 12px;
  opacity: 0;
  display: ${(props) => props.display};
  @media screen and (max-width: 800px) {
    margin: 20px 25px 12px;
  }
`

const TextContaner = styled.div`
  margin: 32px 93px;
  max-width: 672px;
  @media screen and (max-width: 800px) {
    margin: 10px 25px 25px 25px;
  }
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0px 93px;
  @media screen and (min-width: 321px) and (max-width: 800px) {
    margin: 0px 25px 25px;
  }
  @media screen and (max-width: 320px) {
    margin: 0px 25px 25px;
  }
`


export default function Login() {
  const app = useRealmApp();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(context);

  const [policyId, setPolicyId] = useState('');
  const [policyRFC, setpolicyRFC] = useState('');

  const {
    result: locationsResult,
    error: locationsError
  } = useGetPolicyLocations(policyId, policyRFC);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [policyError, setPolicyError] = useState(false);
  const [rfcError, setRFCError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Checks window width so we can set error message position
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resizse", handleResize);
    };
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let action;
    switch (name) {
      case 'externalPolicyId':
        action = actions.SetExternalPolicyId;
        break;
      case 'incumbentRFC':
        action = actions.SetIncumbentRFC;
        break;
      default:
        action = '';
    }
    dispatch({ type: action, payload: value });
  };

  useEffect(() => {
    if (locationsError) {
      setErrorMessage(locationsError.message);
      dispatch({ type: actions.SetIsProcessing, payload: false });
    }
    if (locationsResult && locationsResult.length > 0) {
      // dispatch action to set results into appstate
      dispatch({ type: actions.SetPolicyLocations, payload: locationsResult });
      // Change route to next view
      navigate('/axa/como-funciona');
      // scroll to top
      window.scrollTo(0, 0);
      // Reset is processing
      dispatch({ type: actions.SetIsProcessing, payload: false });
    }
  }, [dispatch, navigate, locationsError, locationsResult])

  const handleValidation = async (e) => {
    e.preventDefault();

    // Is Processing
    dispatch({ type: actions.SetIsProcessing, payload: true });

    try {
      // reset values
      if (state.external_policy_id && state.incumbent_rfc) {
        setErrorMessage('');
        setPolicyError(false);
        setRFCError(false);
      }

      if (!state.external_policy_id || state.external_policy_id === '') {
        setPolicyError(true);
        throw new Error('Falta información');
      } else if (state.external_policy_id) {
        setPolicyError(false);
      }

      if (!state.incumbent_rfc || state.incumbent_rfc === '') {
        setRFCError(true);
        throw new Error('Falta información');
      } else if (state.external_policy_id) {
        setRFCError(false);
      }

      const credentials = Realm.Credentials.anonymous();
      await app.logIn(credentials);

      setPolicyId(state.external_policy_id);
      setpolicyRFC(state.incumbent_rfc);
    } catch (err) {
      setErrorMessage(err.message);
      dispatch({ type: actions.SetIsProcessing, payload: false });
    }
  }
  return (
    <PageContainer>
      <Header />
      <BackLinkContainer display="block">
        <BackLink to="/">&nbsp;</BackLink>
      </BackLinkContainer>
      <StyledDiv>
        <SubTitle>AXA México</SubTitle>
        <Title>Ayuda Express Huracán</Title>
      </StyledDiv>
      <TextContaner>
        <Text>Como parte de nuestro compromiso hacia nuestros asegurados, te ofrecemos una mayor protección en el Pacífico. Durante esta temporada de huracanes 2023, protege tu negocio ante pérdidas económicas tras el paso de un huracán categoría 3, 4 ó 5.</Text>
      </TextContaner>
      <StyledForm onSubmit={(e) => handleValidation(e)}>
        <FlexRow justifyContent="start" width="100%" responsiveWidth="800px">
          <TextInput
            name="externalPolicyId"
            label="Póliza"
            placeholder="YHM1254896350"
            onChange={handleChange}
            value={state.external_policy_id}
            maxLength={13}
            error={policyError}
            errorMessage={windowWidth > 800 ? errorMessage : ''}
            errorMessageContainer={windowWidth > 800}
          />
          <TextInput
            name="incumbentRFC"
            label="RFC del contratante"
            placeholder="ABCD123456AAA"
            onChange={handleChange}
            value={state.incumbent_rfc}
            maxLength={13}
            error={rfcError}
            errorMessage={windowWidth <= 800 ? errorMessage : ''}
            errorMessageContainer={windowWidth <= 800}
          />
        </FlexRow>
        <Button
          text="Avanzar"
          defaultValue="submit"
          disabled={state.is_processing}
          isProcessing={state.is_processing}
        />
        <div style={{height: "100px"}} />
      </StyledForm>
      <Footer position="fixed" />
    </PageContainer>
  );
}
