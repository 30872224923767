import { useEffect } from 'react';

/**
 * useTrigger hook for triggering functions when trigger changes to true.
 * @param {boolean} trigger - The variable to listen to as a trigger
 * @param {Function} functionToTrigger - The function to trigger
 */

const useTrigger = (trigger, functionToTrigger) => {
  useEffect(() => {
    if (trigger) {
      functionToTrigger();
    }
  }, [trigger]);
};

export default useTrigger;
