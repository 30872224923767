import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`

export default function FlexColumn(props) {
  return (
    <StyledDiv
      alignItems={props.alignItems}
      justifyContent={props.justifyContent}
      width={props.width}
      height={props.height}
    >
        {props.children}
    </StyledDiv>
  );
}