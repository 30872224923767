import React from "react";
import styled from "styled-components";

import FlexColumn from "../styled-components/layout/FlexColumn";

const BlueContainer = styled.div`
  background-color: #E7EFFB;
  padding: 16px;
  margin-bottom: 32px;
`

const CardTitle = styled.p`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #343C3D;
  margin: 0px 0px 16px 0px;
  @media screen and (max-width: 800px) {
    font-size: 16px;
    line-height: 16px;s
  }
`

const CardText = styled.p`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #343C3D;
  text-align: left;
  padding-right: 65px;
  margin: 0;
  @media screen and (max-width: 800px) {
    font-size: 12px;
    line-height: 16px;
    padding-right: 0px;
  }
`

const BlueText = styled.p`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.913411px;
  text-transform: uppercase;
  color: #00008F;
  margin: 16px 0px 0px 0px;
  @media screen and (max-width: 800px) {
    font-size: 12px;
    line-height: 13px;
  }
`


export default function QuoteDetails(props) {
  return (
    <BlueContainer>
      <FlexColumn>
        <CardTitle>Ubicación Asegurada {props.location_id}</CardTitle>
        <CardText>Ahora tu negocio estará cubierto con Ayuda Express Huracán, durante esta temporada de huracanes 2023.</CardText>
        <BlueText>Suma asegurada total (MXN): ${props.selectedCoverage ? (props.selectedCoverage.cantidad + 10000).toLocaleString() : 0}</BlueText>
        <BlueText>Prima total (MXN): {props.selectedCoverage ? (props.selectedCoverage.prima !== 0 ? `$${props.selectedCoverage.prima.toLocaleString()}` : 'Obsequio') : '$0'}</BlueText>
      </FlexColumn>
    </BlueContainer>
  )
}