import React, { useState, useEffect } from "react";
import styled from "styled-components";

import CoverageTable from "./CoverageTable";

import Historical from "../components/Historical";
import FlexColumn from "../styled-components/layout/FlexColumn";
import FlexRow from "../styled-components/layout/FlexRow";
import TextInput from "../styled-components/TextInput";


const GrayContainer = styled.div`
  background-color: ${(props) => props.isAddressCovered ? '#FAFAFA' : '#E2E2E2'};
  padding: 24px 32px;
  margin-top: 24px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    padding: 25px;
  }
`

const WhiteContainer = styled.div`
  background-color: #FFFFFF;
  // padding: 24px 32px 0px 32px;
  margin-top: 24px;
  display: ${(props) => props.isAddressCovered ? 'flex' : 'none'};
  flex-direction: row;
  @media screen and (min-width: 801px) and (max-width: 960px) {
    flex-direction: column;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`

const BlueContainer = styled.div`
  height: 56px;
  width: 800px;
  background-color: #E7EFFB;
  border: 2px solid #E7EFFB;
  padding: 0px 10px;
  margin: 25px 0px 32px;
  display: ${(props) => props.isAddressCovered ? 'flex' : 'none'};
  flex-direction: row;
  @media screen and (min-width: 801px) and (max-width: 960px) {
    flex-direction: column;
    max-width: 300px;
    height: 100%;
    margin: 16px 0px;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 16px 0px 0px;
    padding: 0px;
  }
`

const InvalidInfoContainer = styled.div`
  height: 48px;
  width: fit-content;
  background-color: #C91432;
  color: white;
  display: ${(props) => props.isAddressCovered ? 'none' : 'flex'};
  align-items: center;
  line-height: 28px;
  padding: 0px 10px;
  margin: 16px 0px 24px;
`
const WhiteText = styled.p`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #FFFFFF;
  @media screen and (max-width: 800px) {
    font-size: 12px;
    line-height: 16px;
  }
`

const CardTitle = styled.h2`
  font-family: 'Publico Headline';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #343C3D;
  margin: 0px 0px 16px;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    line-height: 26px;
  }
`

const VerticalDivider = styled.div`
  border-right: 1px solid #00008F;
  margin: 10px 35px 10px 40px;
`

const BlueDivider = styled.div`
  height: 24px;
  border-bottom: 1px solid #00008F;
  margin-bottom: 24px;
`

const BlueText = styled.p`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #00008F;
  text-transform: uppercase;
  padding: 0px 10px;
`

const BlueTextCoverage = styled(BlueText)`
  @media screen and (max-width: 960px) {
    margin: 16px 0px 0px 0px;
  }
`

const BlueTextPayment = styled(BlueText)`
  @media screen and (max-width: 960px) {
    margin: 16px 0px;
  }
`

export default function AddressTable(props) {
  const { isAddressCovered } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [selectedCover, setSelectedCover] = useState(null);
  const [selectedPremium, setSelectedPremium] = useState(null);

  // Checks window width so we can set error message position
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <FlexColumn>
      <GrayContainer isAddressCovered={isAddressCovered}>
        <InvalidInfoContainer isAddressCovered={isAddressCovered}>
          <WhiteText>Nota: Esta ubicación no cumple con el criterio de encontrarse en un estado del Pacífico a máximo 100 km de la costa.</WhiteText>
        </InvalidInfoContainer>
        <CardTitle>Ubicación {props.location_id}</CardTitle>
        <FlexRow justifyContent="start" width="100%" responsiveWidth="1175px">
          <TextInput type="text" label="RFC" value={props.holder_rfc} placeholder="" disabled={true} />
        </FlexRow>
        <FlexRow justifyContent="start" width="100%" responsiveWidth="1175px">
          <TextInput type="text" label="Nombre / Razón social" value={props.holder_first_name} placeholder="" disabled={true} />
          <TextInput type="text" label="Apellido paterno" value={props.holder_last_name_1} placeholder="" disabled={true} />
          <TextInput type="text" label="Apellido materno" value={props.holder_last_name_2} placeholder="" disabled={true} />
        </FlexRow>
        <FlexRow justifyContent="start" width="100%" responsiveWidth="1175px">
          <TextInput type="text" label="Calle" value={props.street} placeholder="" disabled={true} />
          <TextInput type="text" label="Número exterior" value={props.exterior_number} placeholder="" disabled={true} />
          <TextInput type="text" label="Número interior" value={props.interior_number} placeholder="" disabled={true} />
        </FlexRow>
        <FlexRow justifyContent="start" width="100%" responsiveWidth="1175px">
          <TextInput type="text" label="Colonia" value={props.suburb} placeholder="" disabled={true} />
          <TextInput type="text" label="Estado" value={props.state} placeholder="" disabled={true} />
          <TextInput type="text" label="Ciudad" value={props.city} placeholder="" disabled={true} />
        </FlexRow>
        <FlexRow justifyContent="start" width="100%" responsiveWidth="1175px">
          <TextInput type="text" label="Población" value={props.town} placeholder="" disabled={true} />
          <TextInput type="text" label="Código postal" value={props.zip_code} placeholder="" disabled={true} />
        </FlexRow>
      </GrayContainer>
      <WhiteContainer isAddressCovered={isAddressCovered}>
        <FlexColumn>
          <CoverageTable {...props} setSelectedCover={setSelectedCover} setSelectedPremium={setSelectedPremium} />
        </FlexColumn>
        {windowWidth <= 960 ? (
          <BlueContainer isAddressCovered={isAddressCovered}>
            <BlueTextCoverage>Suma Asegurada Total (MXN): ${selectedCover ? selectedCover.toLocaleString() : ''}</BlueTextCoverage>
            <BlueTextPayment>Prima Total (MXN): {selectedPremium ? `$${selectedPremium.toLocaleString()}` : 'Obsequio'}</BlueTextPayment>
          </BlueContainer>
        ) : ''}
        {windowWidth > 960 ? <VerticalDivider /> : <BlueDivider />}
        {props.within_shape ? (
          <FlexColumn>
            <Historical state={props.state} payoutAmount={selectedCover} />
          </FlexColumn>
        ) : null}
      </WhiteContainer>
      {windowWidth > 960 ? (
        <BlueContainer isAddressCovered={isAddressCovered}>
          <BlueTextCoverage>Suma Asegurada Total (MXN): ${selectedCover ? selectedCover.toLocaleString() : ''}</BlueTextCoverage>
          <VerticalDivider />
          <BlueTextPayment>Prima Total (MXN): {selectedPremium ? `$${selectedPremium.toLocaleString()}` : 'Obsequio'}</BlueTextPayment>
        </BlueContainer>
      ) : ''}
    </FlexColumn>
  )
}