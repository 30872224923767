import React from "react";
import styled from "styled-components";

const StyledText = styled.h3`
    color: #343C3D;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 1px;
    text-align: left;
    text-transform: uppercase;
    margin: 0px 93px;
    @media screen and (max-width: 800px) {
      font-size: 8px;
      line-height: 150%;
      letter-spacing: 0.668848px;
      margin: 0px 25px;
    }
`

export default function SubTitle(props) {
  return (
    <StyledText>
        {props.children}
    </StyledText>
  );
}