import React from "react";
import styled from "styled-components";

const StyledH1 = styled.h1`
  color: #343C3D;
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  font-family: 'Publico Headline';
  text-align: left;
  margin: 0px 93px;
  @media screen and (max-width: 800px) {
    font-size: 24px;
    line-height: 150%;
    margin: 0px 25px;
  }
`

export default function Title(props) {
  return (
    <StyledH1>
        {props.children}
    </StyledH1>
  );
}