import React from "react";
import styled from "styled-components";

const Label = styled.label`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #343C3D;
  display: block;
  position: relative;
  padding-left: 40px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media screen and (max-width: 800px) {
    font-size: 14px;
    line-height: 150%;
  }
`

// To create a custom checkmark we need to hide native checkbox but make it available for screen readers.
const HiddenCheckBox = styled.input.attrs({type: "checkbox" })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`
// Checkmark indicator is styled in App.css
const CheckMark = styled.span`
  position: absolute;
  top: 10px;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #00008F;
  :after {
    content: "";
    position: absolute;
    display: none;
  }
`

export default function CheckBox(props) {
   const { checked, children, onChange } = props;
  return (
    <Label className="checkbox-container">
      <HiddenCheckBox className="checkbox" onChange={onChange} checked={checked} />
      <CheckMark className="checkmark" />
      {children}
    </Label>
  );
}