import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import './fonts/Publico/PublicoHeadline-Bold.ttf';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { RealmAppProvider } from './RealmApp';
import { StateProvider } from './state/context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <RealmAppProvider appId={'ayuda-express-production-eecaf'}>
        <StateProvider>
          <App />
        </StateProvider>
      </RealmAppProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
