import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  @media screen and (max-width: ${(props) => props.responsiveWidth}) {
    flex-direction: column;
  }
`

export default function FlexRow(props) {
  const { alignItems, justifyContent, width, height, children, responsiveWidth, margin} = props;
  return (
    <StyledDiv
      alignItems={alignItems}
      justifyContent={justifyContent}
      width={width}
      height={height}
      margin={margin}
      responsiveWidth={responsiveWidth}
    >
        {children}
    </StyledDiv>
  );
}