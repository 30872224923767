// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';
import { useRealmApp } from '../RealmApp';

const useGetPolicyLocations = (externalPolicyId, rfc) => {
  const app = useRealmApp();
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (
      app.currentUser &&
      app.currentUser.functions &&
      externalPolicyId !== '' &&
      rfc !== ''
    ) {
      const getPolicyLocations = async () => {
        try {
          const { enrollmentsList } = app.currentUser.functions;
          const res = await enrollmentsList({ query: { externalPolicyId, rfc } });
          if (res.length === 0) {
            throw new Error('Lo sentimos, no encontramos coincidencias. Cualquier duda o aclaración, por favor contacta a tu agente.');
          }
          setResult(res);
        } catch (e) {
          setError(e);
        }
      };
      
      getPolicyLocations();
    }
  }, [app.currentUser, externalPolicyId, rfc]); // dependency array; only re-run the effect if app, externalPolicyId, or rfc changes

  return { result, error };
};

export default useGetPolicyLocations;

