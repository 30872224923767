// The store file is the container [Context] for the global state of our storefront.
import React, { createContext, useReducer } from 'react';

import { appState } from './state';
import stateReducer from './reducer';

export const context = createContext({
  state: appState,
  dispatch: () => null,
});

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(stateReducer, appState);

  return (
    <context.Provider value={{ state, dispatch }}>
      {children}
    </context.Provider>
  );
};
