import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";

import { context } from '../state/context';
import actions from '../state/actions';

import Add from "./icons/Add";


const CoverageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
`

const CoverageButton = styled.button`
  width: 320px;
  height: 61px;
  background-color: #FFFFFF;
  border: 1px solid #8F8F8F;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent};
  align-items: center;
  padding: 20px;
  margin: 10px 0px;
  :hover {
    cursor: pointer;
    transform: scale(1.1);
    background-color: #E7EFFB;
  }
  @media screen and (min-width: 801px) and (max-width: 960px) {
    min-width: 320px;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`

const CoverageAmount = styled.span`
  font-family: 'Publico Headline';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #8F8F8F;
`

const CoverageNoAmount = styled(CoverageAmount)`
  font-size: 20px;
  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
`

const CoverageText = styled.span`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #8F8F8F;
`

const CoverageLabel = styled(CoverageText)`
  color: #00008F;
`

const CoverageLabelContainer = styled.div`
  margin-bottom: 5px;
`

const DividerContainer = styled.div`
  width: 320px;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  @media screen and (min-width: 801px) and (max-width: 960px) {
    min-width: 320px;
  }
  @media screen and (max-width: 800px) {
    min-width: 100%;
  }
`

const IconContainer = styled.div`
  margin: 0px 25px;
`

const Divider = styled.div`
  height: 12px;
  width: 40%;
  border-bottom: 1px solid #00008F;
  margin-bottom: 25px;
  padding-bottom: 2px;
`


export default function CoverageTable(props) {
  const { setSelectedCover, setSelectedPremium, isAddressCovered } = props;
  const { dispatch } = useContext(context);
  const [loaded, setLoaded] = useState(false);
  const [covers, setCovers] = useState([
    { key: 'key-1', cover: 'coverage_amount_0', cantidad: 0, prima: 0, selected: true },
    { key: 'key-2', cover: 'coverage_amount_1', cantidad: Number(props.coverage_amount_1), prima: Number(props.total_prime_1), selected: false },
    { key: 'key-3', cover: 'coverage_amount_2', cantidad: Number(props.coverage_amount_2), prima: Number(props.total_prime_2), selected: false },
    { key: 'key-4', cover: 'coverage_amount_3', cantidad: Number(props.coverage_amount_3), prima: Number(props.total_prime_3), selected: false },
  ]);

  useEffect(() => {
    // Initials component state
    if (!loaded && isAddressCovered) {
      dispatch({
        type: actions.SetEnrollmentCoverage,
        payload: {
          cover: covers[0],
          locationId: props.location_id
        }
      });
      setSelectedCover(covers[0].cantidad + 10000);
      setSelectedPremium(covers[0].prima);
      setLoaded(true);
    }
  }, [dispatch, setSelectedCover, setSelectedPremium, covers, props.location_id, loaded, isAddressCovered]);

  const onClick = (e, key) => {
    e.preventDefault();
    setCovers(covers.map((cover) => {
      if (cover.key === key) {
        const _cover = {
          ...cover,
          selected: true
        };
        // Set the application state
        dispatch({
          type: actions.SetEnrollmentCoverage,
          payload: {
            cover: _cover,
            locationId: props.location_id
          }
        });
        setSelectedCover(_cover.cantidad + 10000);
        setSelectedPremium(_cover.prima);
        return _cover
      } else {
        return {
          ...cover,
          selected: false
        };
      }
    }));
  }

  return (
    <CoverageContainer>
      <CoverageLabelContainer>
        <CoverageLabel>Cobertura incluida (MXN)</CoverageLabel>
      </CoverageLabelContainer>
      <CoverageButton className="selected" justifyContent="space-between" onClick={(e) => e.preventDefault()}>
        <CoverageAmount>$10,000</CoverageAmount>
        <CoverageText>Obsequio</CoverageText>
      </CoverageButton>
      <DividerContainer>
        <Divider />
        <IconContainer>
          <Add />
        </IconContainer>
        <Divider />
      </DividerContainer>
      <CoverageLabelContainer>
        <CoverageLabel>Incrementa tu cobertura (MXN)</CoverageLabel>
      </CoverageLabelContainer>
      {covers.map((cover, index) => {
        const key = `key-${index + 1}`;
        return (
          <CoverageButton
            key={key}
            className={cover.selected ? 'selected' : ''}
            justifyContent="space-between"
            onClick={(e) => onClick(e, key)}
          >
            {cover.cantidad === 0 ? <CoverageNoAmount>No deseo incrementarla</CoverageNoAmount> : <CoverageAmount>${cover.cantidad.toLocaleString()}</CoverageAmount>}
            <CoverageText>{cover.cantidad === 0 ? '' : `Prima: $${cover.prima.toLocaleString()}`}</CoverageText>
          </CoverageButton>
        )
      })}
    </CoverageContainer>
  );
}
