import React from "react";
import styled from "styled-components";

import Header from "../components/Header";
import Footer from '../components/Footer';
import BackLink from "../styled-components/BackLink";
import Title from "../styled-components/Title";
import SubTitle from "../styled-components/SubTitle";
import Button from "../styled-components/Button";
import Text from "../styled-components/Text";
import FlexColumn from '../styled-components/layout/FlexColumn';
import PageContainer from "../styled-components/layout/PageContainer";
import Confetti from "../styled-components/Confetti";


const BackLinkContainer = styled.div`
  margin: 20px 93px 12px;
  opacity: 0;
  display: ${(props) => props.display};
  @media screen and (max-width: 800px) {
    margin: 20px 25px 12px;
  }
`

const StyledDiv = styled.div`
  margin-bottom: 24px;
  margin-top: 40px;
  @media screen and (max-width: 800px) {
    margin-top: 0px;
    margin-bottom: 10px;
  }
`

const TextContainer = styled.div`
  margin: 0px 93px 25px;
  @media screen and (max-width: 800px) {
    margin: 0px 25px 25px;
  }
  @media screen and (min-width: 801px) {
    max-width: 600px;
  }
`

const StyledButtonContainer = styled.div`
  margin: 0px 93px;
  @media screen and (max-width: 800px) {
    margin: 0px 25px;
    width: -webkit-fill-available;
    width: -moz-available;
  }
`

const A = styled.a`
  color: #00008F;
`


export default function Thanks() {
  return (
    <PageContainer>
      <Header />
      <BackLinkContainer display="block">
        <BackLink to="/gracias">&nbsp;</BackLink>
      </BackLinkContainer>
      <StyledDiv>
        <SubTitle>AXA México</SubTitle>
        <Title>¡Gracias!</Title>
      </StyledDiv>
      <FlexColumn alignItems="start" width="100%" justifyContent="start">
        <TextContainer>
          <Text>Ahora tu negocio estará cubierto con Ayuda Express Huracán, durante esta temporada de huracanes 2023.</Text>
          <br />
          <Text>El endoso con esta cobertura te será entregado por medio de tu agente de seguros. Consulta <A target="_blank" href="https://axa.mx/TyC/Ayuda-Express-Huracan">términos y condiciones</A>.</Text>
        </TextContainer>
        <StyledButtonContainer>
          <br />
          <Button isProcessing={false} text="Regresar a AXA MX" onClick={() => { window.location = 'https://axa.mx/seguro-danos/plan-protege-danos#fragment-454-jlti' }} />
        </StyledButtonContainer>
        <br />
        <br />
        <br />
        <br />
      </FlexColumn>
      <Footer justifyContent="center" position="fixed" />
      <Confetti trigger={true} delay={1000} duration={8000} />
    </PageContainer>
  );
}
