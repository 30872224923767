import React from "react";
import styled from "styled-components";
import ClipLoader from "react-spinners/PuffLoader";


const StyledButton = styled.button`
  height: 48px;
  width: 160px;
  left: 0px;
  top: 0px;
  text-transform: uppercase;
  border-radius: 0px;
  padding: 12px 16px;
  font-family: 'Source Sans Pro';
  background-color: #00008F;
  border: 1px solid #00008F;
  color: white;
  font-style: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  :disabled {
    border: 1px solid #CCCCCC;
    background-color: #F0F0F0;
    color: #CCCCCC;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`

export default function Button(props) {
  return (
    <StyledButton
      id={props.id}
      name={props.name}
      type={props.type}
      onChange={props.onChange}
      onClick={props.onClick}
      defaultValue={props.defaultValue}
      state={props.state}
      disabled={props.disabled}
    >
      {props.text}
      <ClipLoader
        color="#000000"
        loading={props.isProcessing}
        cssOverride={{ position: "absolute", top: "5px", left: "10px", display: "block", margin: "0" }}
        size={25}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </StyledButton>
  );
}