// The main website header that should include the AXA logo and the top copy for AXA México
import React from "react";

import logo from '../axa-logo.svg';


function Header() {
  return (
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
    </header>
  )
}

export default Header;
