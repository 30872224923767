// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';
import { useRealmApp } from '../RealmApp';

const useCreateEnrollmentTask = (payload) => {
  const app = useRealmApp();
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    if (app.currentUser && app.currentUser.functions && payload) {
      const createEnrollmentTask = async () => {
        try {
          const { createEnrollmentTask: createTask } = app.currentUser.functions;
          const res = await createTask({ body: payload });
          setResult(res);
        } catch (e) {
          setError(e);
        }
      };
      
      createEnrollmentTask();
    }
  }, [app.currentUser, payload]); // dependency array; only re-run the effect if app or payload changes

  return { result, error };
};

export default useCreateEnrollmentTask;
