/* eslint-env browser */

import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import confetti from 'canvas-confetti';
import classNames from 'classnames';
import styled from "styled-components";

import useTrigger from '../hooks/useTrigger';

const ConfettiContainer = styled.canvas`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100000;
  pointer-events: none;
`

const defaults = {
  origin: { y: 0.7 },
  ticks: 1000,
};

const Confetti = ({ trigger, delay, duration }) => {
  const count = 200;

  const [isHidden, setIsHidden] = useState(true);
  const canvas = useRef(null);

  const create = useMemo(
    () => (confetti.create(canvas.current, { resize: true })),
    [],
  );

  const fire = useCallback(
    (particleRatio, opts) => {
      create({
        ...defaults,
        ...opts,
        particleCount: Math.floor(count * particleRatio),
      });
    },
    [create],
  );

  const animate = () => {
    setIsHidden(false);
    setTimeout(() => {
      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      });
      fire(0.2, {
        spread: 60,
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      });
      setTimeout(() => {
        setIsHidden(true);
      }, duration);
    }, delay);
  };

  useTrigger(trigger, animate);

  return (
    <ConfettiContainer
      ref={canvas}
      className={classNames(
        'confetti-canvas',
        { 'confetti-canvas--hidden': isHidden },
      )}
    />
  );
};

Confetti.defaultProps = {
  delay: 0,
  duration: 8000,
};

Confetti.propTypes = {
  trigger: PropTypes.bool.isRequired,
  delay: PropTypes.number,
  duration: PropTypes.number,
};

export default Confetti;
