// The main website footer that should include external AXA links a a copyright block
import styled from 'styled-components';

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  background-color: #FAFAFA;
  position: ${(props) => props.position};
  bottom: 0;
  width: 100vw;
  height: 56px;
`

const StyledFooterText = styled.p`
  font-family: Source Sans Pro;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
  text-decoration: none;
  color: #757575;
  padding: 0px 0px 0px 93px;
  margin-block-start: 0;
  margin-block-end: 0;
  @media screen and (max-width: 800px) {
    padding-left: 25px;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 0.669456px;
  }
`

function Footer(props) {
  const { position } = props;
  return (
    <StyledFooter position={position}>
      <StyledFooterText>© 2023 AXA México, todos los derechos reservados.</StyledFooterText>
    </StyledFooter>
  )
}
  
export default Footer;
  