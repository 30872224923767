import actions from './actions';

/**
  * stateReducer - mutates parts of the state according to the action type
  * @param {object} state - The current application state
  * @param {object} action - The action that mutates the state
  */
const stateReducer = (state, action) => {
  switch (action.type) {
    case actions.SetBeneficiaryEmail:
      return {
        ...state,
        beneficiary: {
          ...state.beneficiary,
          email: action.payload
        }
      };
    case actions.SetBeneficiaryPhone:
      return {
        ...state,
        beneficiary: {
          ...state.beneficiary,
          phone: action.payload
        }
      };
    case actions.SetBeneficiaryBirthdate:
      return {
        ...state,
        beneficiary: {
          ...state.beneficiary,
          birthdate: action.payload
        }
      };
    case actions.SetBeneficiaryAccountNumber:
      return {
        ...state,
        beneficiary: {
          ...state.beneficiary,
          bank_account_number: action.payload
        }
      };
    case actions.SetBeneficiaryIssuerId:
      return {
        ...state,
        beneficiary: {
          ...state.beneficiary,
          issuer_id: action.payload
        }
      };
    case actions.SetBeneficiaryIdNumber:
      return {
        ...state,
        beneficiary: {
          ...state.beneficiary,
          id_number: action.payload
        }
      };
    case actions.SetBeneficiaryTypeId:
      return {
        ...state,
        beneficiary: {
         ...state.beneficiary,
          type_id: action.payload
        }
      };
    case actions.SetExternalPolicyId:
      return {
        ...state,
        external_policy_id: action.payload
      };
    case actions.SetIncumbentRFC:
      return {
        ...state,
        incumbent_rfc: action.payload
      };
    case actions.SetPolicyLocations:
      return {
        ...state,
        enrollments: action.payload
      };
    case actions.SetIsProcessing:
      return {
        ...state,
        is_processing: action.payload
      };
    case actions.SetIsIdentityDataCorrect:
      return {
        ...state,
        beneficiary: {
          ...state.beneficiary,
          is_identity_data_correct: action.payload
        }
      };
    case actions.SetIsBankAccountCorrect:
      return {
        ...state,
        beneficiary: {
          ...state.beneficiary,
          is_bank_account_number_correct: action.payload
        }
      };
    case actions.SetPrivacyValidation1:
      return {
        ...state,
        beneficiary: {
          ...state.beneficiary,
          privacy_validation_1: action.payload
        }
      };
    case actions.SetPrivacyValidation2:
      return {
        ...state,
        beneficiary: {
          ...state.beneficiary,
          privacy_validation_2: action.payload
        }
      };
    case actions.SetEnrollmentCoverage:
      return {
        ...state,
        enrollments: state.enrollments.map(enrollment => {
          if (action.payload.locationId === enrollment.location_id) {
            return {
              ...enrollment,
              selectedCoverage: action.payload.cover
            };
          }
          return enrollment;
        }),
      };
    default:
      // Throw an error for any unsupported action types
      throw new Error('Unsupported action dispatched to application store state reducer.');
  }
};

export default stateReducer;