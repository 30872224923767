// A react component with state that maps unique strings to image paths. It should have a title that changes according to the selected image, Only one image record should be displayed at the time
import React, { useState } from 'react';
import styled from "styled-components";


const MapText = styled.p`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #343C3D;
  text-align: left;
  margin: 0;
  @media screen and (max-width: 800px) {
    font-size: 14px;
    line-height: 150%;
  }
`

const MapSubtitle = styled.p`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #343C3D;
  margin: 4px 0px 10px;
  @media screen and (max-width: 800px) {
    font-size: 12px;
    line-height: 10px;
    margin-bottom: 8px;
  }
`

const TitleContainer = styled.div`
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  @media screen and (max-width: 800px) {
    margin-bottom: 10px;
  }
`

const TextContainer = styled.div`
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  width: ${(props) => props.width};
  @media screen and (max-width: 800px) {
    padding-right: 24px;
  }
`

const ImageTextContainer = styled.div`
  height: 20px;
  padding: 8px;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #00008F;
  color: white;
  font-family: 'Source Sans Pro';
`

const StyledImage = styled.div`
  background-color: #D9D9D9;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: none;
  background-position: center;
  border: solid 1px #00008F;
  position: relative;
  width: 420px;
  height: 350px;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`

const CardTitle = styled.h2`
  font-family: 'Publico Headline';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #343C3D;
  margin: 0px;
  @media screen and (max-width: 800px) {
    font-size: 24px;
    line-height: 150%;
  }
`


const Historical = (props) => {
  // Define initial state
  const [windowWidth] = useState(window.innerWidth);
  const [imagePaths] = useState({
    'baja-california': { title: 'Huracán Odile', year: '2014', ratio: 0.5, path: '/images/odile.png' },
    'baja-california-sur': { title: 'Huracán Odile', year: '2014', ratio: 0.5, path: '/images/odile.png' },
    'jalisco': { title: 'Huracán Patricia', year: '2015', ratio: 0.75, path: '/images/patricia.png' },
    'colima': { title: 'Huracán Patricia', year: '2015', ratio: 0.75, path: '/images/patricia.png' },
    'michoacan': { title: 'Huracán Patricia', year: '2015', ratio: 0.75, path: '/images/patricia.png' },
    'guerrero': { title: 'Huracán Patricia', year: '2015', ratio: 0.75, path: '/images/patricia.png' },
    'oaxaca': { title: 'Huracán Patricia', year: '2015', ratio: 0.75, path: '/images/patricia.png' },
    'chiapas': { title: 'Huracán Patricia', year: '2015', ratio: 0.75, path: '/images/patricia.png' },
    'nayarit': { title: 'Huracán Kenna', year: '2002', ratio: 0.75, path: '/images/kenna.png' },
    'sinaloa': { title: 'Huracán Willa', year: '2018', ratio: 0.5, path: '/images/willa.png' },
    'sonora': { title: 'Huracán Willa', year: '2018', ratio: 0.5, path: '/images/willa.png' }
  });

  const stateKey = props.state ? props.state.replace(/[_\s]+/g, '-').toLowerCase().trim() : 'chiapas';
  const [currentKey] = useState(stateKey); // Set default image to be displayed

  const title = !!imagePaths[currentKey] ? imagePaths[currentKey].title : '';
  const year = !!imagePaths[currentKey] ? imagePaths[currentKey].year : '';
  const path = !!imagePaths[currentKey] ? imagePaths[currentKey].path : '';
  const ratio = !!imagePaths[currentKey] ? imagePaths[currentKey].ratio : 0;

  return (
    <div>
      <MapSubtitle>Ejemplo histórico</MapSubtitle>
      <TitleContainer marginBottom="0px" marginTop="10px">
        <CardTitle>{title} {year}</CardTitle>
      </TitleContainer>
      <TextContainer width={windowWidth > 800 ? '420px' : '100%'} marginTop={windowWidth > 800 ? '8px' : '0px'} marginBottom="16px" marginRight="24px">
        <MapText>Esta póliza hubiera pagado ${((props.payoutAmount || 0) * ratio).toLocaleString()} (MXN) luego del {title} en el {year} en este estado de la República Mexicana.</MapText>
      </TextContainer>
      <StyledImage src={path} alt={title} >
        <ImageTextContainer>PAGO HISTÓRICO: <b>${((props.payoutAmount || 0) * ratio).toLocaleString()}</b></ImageTextContainer>
      </StyledImage>
    </div>
  );
}

export default Historical;
