import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
    max-width: 100vw;
    max-height: 100vh;
`

export default function PageContainer(props) {
  return (
    <StyledDiv>
        {props.children}
    </StyledDiv>
  );
}